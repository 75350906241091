<template>
  <app-header :routes="routes" />
  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <sdCards headless>
          <single-shipment-form
            :clientProductItems="productItems"
            :shipmentRequestId="shipmentRequestId"
            :shipment="shipment"
            :currentDestination="{label: shipment.destinationCountryName, value: shipment.destinationCountry}"
            @shipments:submitted="finishEditShipment"
          />
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import {
  computed, defineComponent, ref
} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {Main} from '@/components/shared/styledComponents/Main';
import SingleShipmentForm from '@/components/shipments/Form';

export default defineComponent({
  name: 'ShipmentForm',
  components: {Main, SingleShipmentForm},
  setup() {
    const router = useRouter();
    const shipmentRequestId = computed(() => router.currentRoute.value.params.shipmentRequestId);
    const shipmentId = computed(() => router.currentRoute.value.params.shipmentId);
    const clone = ref(false);


    const {getters, dispatch} = useStore();
    const shipment = computed(() => getters.shipment);
    const productItems = computed(() => getters.productItems);
    clone.value = router.currentRoute._value.name === 'ClientCloneShipment';

    dispatch('toggleLoading', true);
    dispatch('getProductItems').then(async () => {
      clone.value ? await dispatch('cloneShipment', {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value}) : await dispatch('getShipment', {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value});
      dispatch('toggleLoading', false);
    });

    const finishEditShipment = () => {
      router.push({name: 'ClientShipmentRequestDetails', params: {shipmentRequestId: shipmentRequestId.value}});
    };

    const routes = computed(() => [
      {
        name: 'ClientShipmentRequests',
        breadcrumbName: 'layout.shipmentRequest.index'
      },
      {
        name: 'ClientShipmentRequestDetails',
        params: {shipmentRequestId: shipmentRequestId.value},
        breadcrumbName: 'views.shipmentRequest.details'
      },
      {
        name: 'ClientEditShipment',
        params: {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value},
        breadcrumbName: 'views.shipmentRequest.shipments'
      }
    ]);

    return {
      shipmentRequestId,
      shipment,
      productItems,
      routes,
      finishEditShipment,
      clone
    };
  }
});
</script>
